import styled from 'styled-components'
import { Wrapper } from '@renderbus/common/components'
import { Media, typography, hideSMDown, hideSMUp, mbSize } from '@renderbus/common/theme'

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  background: #ffffff;
  border-radius: 20px;
  padding: 80px 0;
  justify-content: space-evenly;
  position: relative;
  .gift-box-img {
    position: absolute;
    right: -40px;
    bottom: -20px;
  }
  ${Media.lessThan(Media.small)} {
    padding: ${mbSize(80)} 0;
    .gift-box-img {
      width: ${mbSize(124)};
      height: ${mbSize(148)};
      right: ${mbSize(-20)};
      bottom: ${mbSize(-30)};
    }
  }
`
export const LotteryContentContainer = styled(ContentContainer)`
  flex-direction: row;
  margin-bottom: 100px;
  position: relative;
  .star-img {
    position: absolute;
    left: -60px;
    top: -60px;
  }
  ${Media.lessThan(Media.small)} {
    margin-bottom: 0;
    .star-img {
      width: ${mbSize(106)};
      height: ${mbSize(119)};
      left: ${mbSize(-20)};
      top: ${mbSize(-50)};
    }
  }
`

export const LotterySubTitle = styled.div`
  font-size: ${typography.text};
  color: #666666;
  line-height: 22px;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(16)};
    line-height: ${mbSize(24)};
  }
`
export const TitleContent = styled.div`
  margin: 0 auto;
  position: relative;
  text-align: center;
  margin-bottom: -67px;
  margin-top: ${p => p.mT || '120px'};
  z-index: 1;
  ${Media.lessThan(Media.small)} {
    margin-bottom: ${mbSize(-67)};
    margin-top: ${p => p.mT || mbSize(80)};
    img {
      height: ${mbSize(80)};
    }
  }
`

export const StepImgWrap = styled.div`
  width: 1095px;
  text-align: center;
  padding: 47px 0 0;
  img {
    padding-bottom: 80px;
    &:nth-child(2) {
      display: none;
    }
  }
  ${Media.lessThan(Media.small)} {
    width: 100%;
    padding: ${mbSize(60)} ${mbSize(36)} 0;
    > img:first-child {
      display: none;
    }
    > img:nth-child(2) {
      display: block;
      margin: auto;
      width: ${mbSize(462)};
      padding-bottom: ${mbSize(60)};
    }
  }
`

export const LotteryContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 240px;
  border-radius: 12px;
  height: 240px;
  align-items: center;
  border: 0;
  background: #f3faf7;
  margin-top: 38px;
  .lottery-title {
    font-size: ${typography.h4};
    color: #111111;
    line-height: 32px;
  }
  .lottery-img {
    display: block;
    margin: 34px auto;
    background-image: ${item => `url(${item.urlSrc})`};
    background-position: center;
    background-repeat: no-repeat;
    height: 68px;
    width: 100%;
  }
  :hover {
    background: linear-gradient(45deg, #29cc7a 0%, #26bf4c 100%);
    box-shadow: 0px 10px 18px 2px rgba(153, 107, 61, 0.3);
    border: 2px solid #ffecb2;
    .lottery-title {
      color: #ffe6b2;
    }
    .lottery-img {
      background-image: ${item => `url(${item.hoverUrlSrc})`};
    }
    ${LotterySubTitle} {
      color: #ffffff;
    }
  }
  ${Media.lessThan(Media.small)} {
    height: ${mbSize(240)};
    width: ${mbSize(200)};
    margin-top: ${mbSize(20)};
    border-width: 1px;
    .lottery-title {
      font-size: ${mbSize(18)};
      line-height: ${mbSize(18)};
      padding-bottom: ${mbSize(20)};
    }
    .lottery-img {
      height: ${mbSize(68)};
      margin: ${mbSize(30)} auto;
      background-size: auto ${mbSize(68)};
    }
  }
`

export const JoinActivityButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 60px;
  background: linear-gradient(180deg, #ff9933 0%, #ff4c6a 100%);
  color: #ffffff;
  outline: none;
  border: none;
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
  margin: 0 auto;
  border-radius: 30px;
  cursor: pointer;
  position: relative;
  &:hover {
    box-shadow: 0px 10px 18px 2px rgba(153, 88, 23, 0.3);
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(320)};
    height: ${mbSize(68)};
    font-size: ${mbSize(30)};
  }
`
export const JoinNowButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 460px;
  height: 80px;
  background: linear-gradient(180deg, #ff9933 0%, #ff4c6a 100%);
  color: #ffffff;
  outline: none;
  border: none;
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
  margin: 0 auto;
  border-radius: 40px;
  cursor: pointer;
  position: relative;
  &:hover {
    box-shadow: 0px 10px 18px 2px rgba(153, 88, 23, 0.3);
  }
  @keyframes zoomIn {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  img {
    position: absolute;
    top: 40px;
    right: -60px;
    animation: zoomIn 1s infinite;
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(520)};
    height: ${mbSize(70)};
    font-size: ${mbSize(28)};
    line-height: 1;
    margin: ${mbSize(100)} auto 0;
    img {
      height: ${mbSize(110)};
      top: 22px;
      right: -30px;
    }
  }
`

export const YearEndRechargeBg = styled.div`
  background: #f2e0ce;
  padding-bottom: 140px;
  ${Media.lessThan(Media.small)} {
    padding-bottom: ${mbSize(152)};
  }
`
export const YearEndWrapper = styled(Wrapper)`
  margin-top: -80px;
  z-index: 3;
  ${Media.lessThan(Media.small)} {
    margin-top: ${mbSize(-115)};
  }
`

export const PrizeList = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 47px 25px 20px;
  ${Media.lessThan(Media.small)} {
    padding: ${mbSize(47)} 0 0;
  }
`

export const PrizeItem = styled.div`
  width: 300px;
  margin-bottom: 60px;
  text-align: center;
  color: #ffffff;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(300)};
    font-size: ${mbSize(24)};
    margin-bottom: ${mbSize(60)};
  }
`
export const PrizeContent = styled.p`
  margin: 30px 0 0;
  color: #111111;
  ${hideSMDown}
`
export const PrizeMbContent = styled.div`
  ${hideSMUp}
  ${Media.lessThan(Media.small)} {
    margin-top: ${mbSize(40)};
    color: #111111;
  }
`
export const PrizeImg = styled.img`
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(300)};
    height: ${mbSize(260)};
  }
`
export const PrizeImgWrap = styled.div`
  width: 100%;
  height: 280px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 0;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(300)};
    height: ${mbSize(260)};
  }
`

export const RulesList = styled.div`
  width: 100%;
  display: flex;
  font-size: 16px;
  line-height: 36px;
  color: #111111;
  text-align: left;
  p {
    min-width: 120px;
    color: #ff9933;
    margin: 0;
  }
  .recharge-gift-img {
    padding: 0;
    margin: 0 3px 2px;
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(20)};
    line-height: ${mbSize(36)};
    p {
      min-width: 70px;
    }
    .recharge-gift-img {
      width: ${mbSize(130)};
      height: ${mbSize(30)};
    }
  }
`
export const HighltText = styled.span`
  color: #12cc75;
  font-weight: bold;
`
export const DescriptionList = styled.div`
  line-height: 36px;
  color: #111111;
  font-size: 16px;
  padding: 46px 141px 0 100px;
  text-align: justify;
  p {
    margin: 0;
    display: flex;
    span {
      min-width: 18px;
    }
  }
  img {
    padding: 0;
    margin: 0 3px 2px;
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(20)};
    padding: ${mbSize(48)} ${mbSize(31)} 0 ${mbSize(37)};
    line-height: ${mbSize(36)};
    img {
      width: ${mbSize(130)};
      height: ${mbSize(30)};
    }
    p {
      span {
        min-width: ${mbSize(20)};
      }
    }
  }
`
